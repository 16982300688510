import React, { useState, useEffect, useContext } from 'react';
import Layout from '../../responsive/layout';
import Topbar from '../../responsive/topbar';
import communicationService from '../../../services/communication';
import { LoadingComponent } from '../../loading';
import { returnWhatsapp } from '../../../utils/browser';
import { useStatus, StatusProps } from '../../../hooks/user.hook';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../context/lang.context';
import Button from '../../button/button';

export const DynamicUserStatus = ({ statusType, intent, communicationToken }: StatusProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETURN_WHATSAPP
          DYNAMIC_USER_FORM {
            FIELDS {
              DOCUMENT {
                TYPE {
                  LABEL
                  PLACEHOLDER
                }
                NUMBER {
                  LABEL_2
                }
              }
            }
          }
        }
      }
    }
  `);

  const [communicationTokenSent, setCommunicationTokenSent] = useState(false);
  const { status, setStatus } = useStatus({ statusType, intent, communicationToken });

  useEffect(() => {
    // please note the communication token only works hand in hand with an existing intent
    if (communicationToken && intent) {
      communicationService
        .postCommunicationToken({ token: communicationToken, text: intent })
        .catch(() => {
          // if communication token fails, we present generic "failed" screen;
          // @todo: we might want to improve this with a more specific message
          setStatus('failed');
        })
        .finally(() => setCommunicationTokenSent(true));
    }
  }, [communicationToken]);

  // wait until communication token is succesfully sent before displaying status message
  // (if there's no intent, there's nothing to wait here...)
  if (communicationToken && intent && !communicationTokenSent) {
    return <LoadingComponent />;
  }

  return (
    <Layout>
      <Topbar />
      <section className="content text-center">
        <h1
          className={`heading mx-auto mb-6 ${status.error ? 'text-red' : 'text-primary-900'}`}
          style={{ maxWidth: 300 }}
          dangerouslySetInnerHTML={{ __html: status.title }}
        />

        <img className="mx-auto mb-10" src={status.image} alt="frame" />

        {status.notePrefix && status.noteSuffix && status.whatsapp && (
          <p className="text-gray mb-10 mx-auto" style={{ maxWidth: 300 }}>
            {status.notePrefix} <b>{status.whatsapp}</b> {status.noteSuffix}
          </p>
        )}

        <Button
          color={'primary'}
          onClick={() => returnWhatsapp({ message: status.whatsapp || '' })}
        >
          {t(data).RETURN_WHATSAPP}
        </Button>
      </section>
    </Layout>
  );
};
