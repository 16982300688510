import { AddFieldProps } from '../types';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import { CompanyContext } from '../../../../context/company.context';
import { LangContext } from '../../../../context/lang.context';
import { FieldLabel } from '../field-label';

export const GenderField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { clientId } = useContext(CompanyContext);
  const { t, lang } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              GENDER {
                HEADING
                REQUIRED
              }
            }
          }
        }
      }
      allGenderService {
        nodes {
          locale
          clientId
          GENDER_TYPE {
            id
            value
          }
        }
      }
    }
  `);

  const genderList = data.allGenderService.nodes.find(node => node.clientId === clientId && node.locale === lang).GENDER_TYPE;

  const genderField = fields?.gender!;
  if (genderField?.required) {
    schemas.gender = schemas?.gender!.required(t(data).DYNAMIC_USER_FORM.FIELDS.GENDER.REQUIRED);
  }

  return (
    <div key="gender" className="mb-5 mt-10">
      <FieldLabel heading={t(data).DYNAMIC_USER_FORM.FIELDS.GENDER.HEADING} required={genderField?.required} />
      <div className="flex flex-wrap mb-2">
        {genderList.map(({ id, value }: { id: string; value: string }) => (
          <label key={id} className="w-1/2 flex pb-3">
            <input
              type="radio"
              value={id}
              checked={formik?.values.gender === id}
              onChange={formik?.handleChange('gender') || (() => ({}))}
              name={id}
              className="mr-2 mt-1"
              disabled={genderField?.readOnly}
            />
            <span className="leading-tight">{value}</span>
          </label>
        ))}
      </div>
      {formik?.errors.gender && (
        <p className="text-red text-sm">
          <span className="icon-exclamation-triangle mr-1" />
          {formik?.errors.gender}
        </p>
      )}
    </div>
  );
};
