import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/responsive/layout';
import Portrait from '../../components/responsive/portrait';
import { DynamicUserForm } from '../../components/forms/dynamic-user';
import { DynamicUserStatus } from '../../components/forms/dynamic-user/status';
import appService from '../../services/endpoints';
import { navigateToCustomPage, navigateToError, navigateToFailed, navigateToSuccess } from '../../utils/navigate';
import { Config, Fields } from '../../components/forms/dynamic-user/types';
import { format, IncomingDTO } from '../../components/forms/dynamic-user/utils/format';
import { parse } from '../../components/forms/dynamic-user/utils/parse';
import { useRecaptcha } from '../../hooks/recaptcha.hook';
import { LoadingComponent } from '../../components/loading';
import { Footer } from '../../components/footer/footer';
import pages from '../../constants/pages.json';
import { LangContext } from '../../context/lang.context';
import { graphql, useStaticQuery } from 'gatsby';
import communicationService from '../../services/communication';
import { CreditcardContext } from '../../context/creditcard.context';

const DynamicUser = ({ getToken }: PageProps) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            TITLE_1
            TITLE_2
            TITLE_3
            TITLE_4
            TITLE_5
            DETAILS
          }
        }
      }
    }
  `);

  const [config, setConfig] = useState<Config | null>(null);
  const [status, setStatus] = useState<'success' | 'failed' | 'expired'>();
  const [statusIntent, setStatusIntent] = useState('');
  const [communicationToken, setCommunicationToken] = useState();
  const [isAliasError, setIsAliasError] = useState(false);
  const [isPinError, setIsPinError] = useState<'repeated' | 'userBirthDate'>();
  const { getRecaptchaToken } = useRecaptcha(!!config?.fields.pin);
  const [isResponseFinished, setIsResponseFinished] = useState<boolean>(true);
  const token = getToken();
  const { setLoading } = useContext(CreditcardContext);
  const handleSubmit = async (data: Fields<string>) => {
    setIsAliasError(false);
    setIsResponseFinished(false);
    setIsPinError(undefined);
    try {
      const recaptchaToken = config?.fields.pin ? await getRecaptchaToken() : null;
      const { action, url, intent, feedback, communication_token } = await appService.patchDynamicUserData(
        token,
        parse(data, recaptchaToken)
      );

      if (action === 'redirect') {
        navigateToCustomPage(`/${url}`)?.();
      } else if (action === 'paid') {
        const state = {
          from: pages.SUCCESS_PAGE.PAID,
          response: feedback,
        };
        navigateToSuccess({ state, callback: () => setLoading?.(false) })?.();
      } else if (action === 'finished') {
        communication_token &&
          intent &&
          communicationService
            .postCommunicationToken({ token: communication_token, text: intent })
            .catch(error => console.log(error));
        const state = {
          from: pages.SUCCESS_PAGE.UPDATE_CUSTOMER,
          response: feedback,
        };
        navigateToSuccess({ state })?.();
      } else if (action === 'custom_error' && feedback.error_code === 'FGS1903') {
        const state = {
          from: pages.FAILED_PAGE.DENIED,
        };
        navigateToFailed({ state })?.();
      } else if (
        action === 'custom_error' &&
        (feedback.error_code === 'FGS1500' || feedback.error_code === 'FGS1700')
      ) {
        setStatus('failed');
      } else if (action === 'custom_error' && feedback.error_code === 'FGS2304') {
        setIsAliasError(true);
      } else if (action === 'custom_error' && feedback.error_code === 'FGS1904') {
        setIsResponseFinished(true);
        setIsPinError('repeated');
      } else if (action === 'custom_error' && feedback.error_code === 'FGS1905') {
        setIsPinError('userBirthDate');
      } else if (action === 'custom_error' && feedback.error_code === 'FGS2300') {
        const state = {
          from: pages.FAILED_PAGE.ALIAS,
        };
        navigateToFailed({ state })?.();
      } else if (action === 'custom_error' && ['FGS2301', 'FGS2302', 'FGS2303'].includes(feedback.error_code)) {
        const state = {
          from: pages.FAILED_PAGE.ALIAS,
          response: 'alias',
        };
        navigateToFailed({ state })?.();
      } else {
        setStatus('success');
        setStatusIntent(intent);
        // @todo: instead of piling up more logic here, we could probably abstract out
        // this communication token logic into a separate provider, or something
        setCommunicationToken(communication_token);
      }
    } catch (error: any) {
      if (error.response?.data?.details[0]?.error_code === 'FGS1903') {
        const state = {
          from: pages.FAILED_PAGE.DENIED,
        };
        navigateToFailed({ state })?.();
      } else if (error.response?.data?.details[0]?.error_code === 'FGS1100') {
        setStatusIntent(error.response?.data?.intent_error);
        setStatus('expired');
      } else if (error.response?.data?.details[0]?.error_code === 'FGS1904') {
        setIsPinError('repeated');
        setIsResponseFinished(true);
      } else if (error.response?.data?.details[0]?.error_code === 'FGS1905') {
        setIsPinError('userBirthDate');
      } else {
        setStatusIntent(error.response?.data?.intent_error);
        setStatus('failed');
      }
    }
  };

  useEffect(() => {
    if (!token) {
      navigateToError()?.();
    } else {
      appService
        .getDynamicUserData<IncomingDTO>(token)
        .then(format)
        .then(({ config }) => setConfig(config))
        .catch(() => navigateToError()?.());
    }
  }, []);

  if (status) {
    return <DynamicUserStatus statusType={status} intent={statusIntent} communicationToken={communicationToken} />;
  }
  
  if (!config) {
    return <LoadingComponent />;
  }
  return (
    <Layout>
      <Portrait>
        <section className="content relative text-center">
          <h1 className="heading text-white mx-auto pt-2 pb-32" style={{ maxWidth: 300 }}>
            {t(data).DYNAMIC_USER_FORM.TITLE_1}
            <b>{` ${t(data).DYNAMIC_USER_FORM.TITLE_2} `} </b>
            <br />
            {t(data).DYNAMIC_USER_FORM.TITLE_3}
            <br />
            <b>{` ${t(data).DYNAMIC_USER_FORM.TITLE_4}`}</b>
            {t(data).DYNAMIC_USER_FORM.TITLE_5}
            <span className="text-base leading-6 pt-3 block">{t(data).DYNAMIC_USER_FORM.DETAILS}</span>
          </h1>
        </section>
      </Portrait>

      <section className="content">
        <DynamicUserForm
          config={config}
          submit={handleSubmit}
          token={token}
          isAliasError={isAliasError}
          isResponseFinished={isResponseFinished}
          isPinError={isPinError}
        />
      </section>
      <Footer />
    </Layout>
  );
};

export default DynamicUser;
