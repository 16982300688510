import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, ChangeEvent } from 'react';
import { LangContext } from '../../../../context/lang.context';
import { AddFieldProps } from '../types';
import { getMultiCompanyPath } from '../../../../utils/navigate';

export const AgreementField = ({ fields, formik, setDisabled }: AddFieldProps) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            AGREEMENT {
              I_HAVE_READ
              TERMS
              AND
              PRIVACY
            }
          }
        }
      }
    }
  `);

  // @note: need to check for "not true", because value could come as `null` as well
  if (fields?.agreement!.required && fields?.agreement!.defaultValue !== 'true') {
    return (
      <div key="agreement" className="mb-10 mt-10 text-sm">
        <label className="flex gap-4 items-baseline ">
          <input
            type="checkbox"
            name="agreement"
            className="mr-2 mt-1"
            onChange={(e: ChangeEvent<any>) => {
              formik?.handleChange(e);
              formik?.setFieldValue('agreement', e.currentTarget.checked ? 'true' : 'false');
              setDisabled?.((disabled: boolean) => !disabled);
            }}
          />
          <span>
            {t(data).DYNAMIC_USER_FORM.AGREEMENT.I_HAVE_READ}{' '}
            <a href={getMultiCompanyPath('/site/terms')} target="_blank" className="text-primary">
              {t(data).DYNAMIC_USER_FORM.AGREEMENT.TERMS}
            </a>{' '}
            {t(data).DYNAMIC_USER_FORM.AGREEMENT.AND}{' '}
            <a href={getMultiCompanyPath('/site/privacy')} target="_blank" className="text-primary">
              {t(data).DYNAMIC_USER_FORM.AGREEMENT.PRIVACY}
            </a>{' '}
          </span>
        </label>
      </div>
    );
  } else {
    return <div></div>;
  }
};
