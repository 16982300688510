import MaskedInput from 'react-text-mask';
import { AddFieldProps } from '../types';
import { createMask } from '../../../../utils/masks';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import { LangContext } from '../../../../context/lang.context';
import { FieldLabel } from '../field-label';

export const LastNameField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              LAST_NAME {
                HEADING
                REQUIRED
                SIDE_NOTE
              }
            }
          }
        }
      }
    }
  `);
  const lastNameField = fields?.lastName!;

  if (lastNameField.required || lastNameField.defaultValue) {
    schemas.lastName = schemas?.lastName!.required(t(data).DYNAMIC_USER_FORM.FIELDS.LAST_NAME.REQUIRED);
  }
  return (
    <div key="lastName" className="mb-5 mt-10">
      <FieldLabel
        heading={t(data).DYNAMIC_USER_FORM.FIELDS.LAST_NAME.HEADING}
        sideNote={t(data).DYNAMIC_USER_FORM.FIELDS.LAST_NAME.SIDE_NOTE}
        required={lastNameField?.required}
      />
      <MaskedInput
        value={formik?.values.lastName}
        onChange={formik?.handleChange('lastName')}
        className={`input ${formik?.errors.lastName && 'border-red'} ${lastNameField.readOnly && 'bg-gray-200'}`}
        mask={createMask('NAME', 40)}
        guide={false}
        disabled={lastNameField.readOnly}
      />
      {formik?.errors.lastName && (
        <p className="text-red text-sm">
          <span className="icon-exclamation-triangle mr-1" />
          {formik?.errors.lastName}
        </p>
      )}
    </div>
  );
};
