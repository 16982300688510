import { AddFieldProps } from '../types';
import MaskedInput from 'react-text-mask';
import { createMask } from '../../../../utils/masks';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import { LangContext } from '../../../../context/lang.context';
import { FieldLabel } from '../field-label';

export const FirstNameField = ({ fields, formik, schemas }: AddFieldProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              FIRST_NAME {
                HEADING
                SIDE_NOTE
                REQUIRED
              }
            }
          }
        }
      }
    }
  `);
  const firstNameField = fields?.firstName!;

  if (firstNameField?.required || firstNameField?.defaultValue) {
    schemas.firstName = schemas?.firstName!.required(t(data).DYNAMIC_USER_FORM.FIELDS.FIRST_NAME.REQUIRED);
  }

  return (
    <div key="firstName" className="mb-5">
      <FieldLabel
        heading={t(data).DYNAMIC_USER_FORM.FIELDS.FIRST_NAME.HEADING}
        sideNote={t(data).DYNAMIC_USER_FORM.FIELDS.FIRST_NAME.SIDE_NOTE}
        required={firstNameField?.required}
      />
      <MaskedInput
        value={formik?.values.firstName}
        onChange={formik?.handleChange('firstName')}
        className={`input ${formik?.errors.firstName && 'border-red'} ${firstNameField?.readOnly && 'bg-gray-200'}`}
        mask={createMask('NAME', 40)}
        guide={false}
        disabled={firstNameField?.readOnly}
      />
      {formik?.errors.firstName && (
        <p className="text-red text-sm">
          <span className="icon-exclamation-triangle mr-1" />
          {formik?.errors.firstName}
        </p>
      )}
    </div>
  );
};
