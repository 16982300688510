import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import { LangContext } from '../../../../context/lang.context';
import { FieldLabel } from '../field-label';
import { AddFieldProps } from '../types';

export const EmailField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              EMAIL {
                HEADING
              }
            }
          }
          USER_DETAIL {
            FORM {
              VALIDATION {
                EMAIL_FORMAT_NOT_VALID
              }
            }
          }
        }
      }
    }
  `);

  const emailField = fields?.email!;

  schemas.email = schemas?.email!.email(t(data).USER_DETAIL.FORM.VALIDATION.EMAIL_FORMAT_NOT_VALID);

  if (emailField?.required || emailField?.defaultValue) {
    schemas.email = schemas?.email
      .required(t(data).USER_DETAIL.FORM.VALIDATION.EMAIL_FORMAT_NOT_VALID)
      .matches(
        /^[a-zA-Z0-9._+&*-]+@[a-zA-Z0-9._+&*-]+\.[a-zA-Z]{2,7}$/,
        t(data).USER_DETAIL.FORM.VALIDATION.EMAIL_FORMAT_NOT_VALID
      );
  }

  return (
    <div key="email" className="mb-5 mt-10">
      <FieldLabel heading={t(data).DYNAMIC_USER_FORM.FIELDS.EMAIL.HEADING} required={emailField?.required} />
      <input
        value={formik?.values.email}
        onChange={formik?.handleChange('email')}
        className={`input ${formik?.errors.email && 'border-red'}`}
        maxLength={65}
        type="email"
      />
      {formik?.errors.email && (
        <p className="text-red text-sm">
          <span className="icon-exclamation-triangle mr-1" />
          {formik?.errors.email}
        </p>
      )}
    </div>
  );
};
