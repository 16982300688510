import { css } from '@emotion/react';
import React, { useContext, useRef, useState } from 'react';
import * as Yup from 'yup';
import { AddFieldProps } from '../types';
import { CompanyContext } from '../../../../context/company.context';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../../context/lang.context';
import { AnyObject } from 'yup/lib/types';

export const PinField = ({ fields, schemas, formik, initialValues }: AddFieldProps) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              SECURITY_PIN {
                HEADING
                MODIFY
                PIN {
                  INFO {
                    MIN_LENGTH
                    NO_CONSECUTIVE
                    NO_REPEAT
                  }
                  LABEL
                  NOTE
                  REQUIRED
                }
                PIN_CONFIRM {
                  LABEL
                  REQUIRED
                  MATCH
                }
              }
            }
          }
        }
      }
    }
  `);

  const company = useContext(CompanyContext);
  const { getPublicURL } = useContext(CompanyContext);
  const pendingSvg = getPublicURL(`/theme/assets/images/dynamic-user/pin-note-pending.svg`);
  const doneSvg = getPublicURL(`/theme/assets/images/dynamic-user/pin-note-done.svg`);
  const errorSvg = getPublicURL(`/theme/assets/images/dynamic-user/pin-note-error.svg`);
  const [showPin, setShowPin] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const pinField = fields?.pin!;
  const pinCreated = !pinField?.required;

  schemas.pin = (schemas?.pin! as Yup.StringSchema<string, AnyObject, string>)
    .test('not-consecutive', t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.INFO.NO_CONSECUTIVE, (value: string) => !isConsecutive(value))
    .test('not-repeated', t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.INFO.NO_REPEAT, (value: string) => !isRepeated(value))
    .min(4, t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.INFO.MIN_LENGTH);

  if (pinField?.required) {
    schemas.pin = schemas?.pin.required(t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.REQUIRED);
  }

  initialValues.confirmPin = showPin ? '' : initialValues?.pin;
  schemas.confirmPin = Yup.string().when('pin', (pin: string, schema: Yup.StringSchema) => {
    if (pin && pin.length) {
      return schema
        .required(t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN_CONFIRM.REQUIRED)
        .oneOf([pin], t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN_CONFIRM.MATCH)
        .min(4, t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.INFO.MIN_LENGTH);
    }
    return schema;
  });
  const boxStyle = !pinCreated ? {} : { boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', borderRadius: 6 };

  return (
    <div key="pin" className={'mb-5 ' + (pinCreated ? 'px-3 py-3' : '')} style={boxStyle}>
      <div className="flex">
        <label className="font-bold flex">
          {company.clientId === '727389' ? 'PIN eMe' : t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.HEADING}
          {company.clientId === '727389' && (
            <div className="relative">
              <img
                src={getPublicURL(`/theme/assets/images/icon/info.svg`)}
                className="ml-2"
                onClick={() => setShowInfo(!showInfo)}
              />
              <div className={` ${!showInfo && 'hidden'}`}>
                <div className="absolute w-4 h-4 transform translate-x-8 -translate-y-4 bg-gray-800 rotate-45" />
                <div
                  className="absolute text-xs w-40 p-2 rounded-md transform translate-x-10 -translate-y-10 bg-gray-800 text-white font-thin"
                  onClick={() => setShowInfo(!showInfo)}
                >
                  Por razones de seguridad, evita usar fechas o año de nacimiento.
                </div>
              </div>
            </div>
          )}
        </label>
        {!pinCreated ? null : showPin ? (
          <span className="icon-close icon-black mr-1 cursor-pointer ml-auto" onClick={() => setShowPin(!showPin)} />
        ) : (
          <a
            onClick={() => setShowPin(!showPin)}
            className="text-primary text-sm bg-transparent border-b border-primary outline-none cursor-pointer ml-auto"
          >
            {t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.MODIFY}
          </a>
        )}
      </div>

      {(showPin || !pinCreated) && (
        <div className={!pinCreated ? 'mt-3' : 'px-2 pt-8'}>
          {!pinCreated && (
            <label className="block mb-3">{t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.LABEL}</label>
          )}
          <PinInput formik={formik} fieldName="pin" />
          {formik?.errors.pin && (
            <p className="text-red text-sm">
              <span className="icon-exclamation-triangle mr-1" />
              {formik?.errors.pin}
            </p>
          )}

          <ul className="my-8">
            <li className="flex gap-2">
              <img
                src={!formik?.values.pin ? pendingSvg : formik?.values.pin.length < 4 ? errorSvg : doneSvg}
                alt="SVG"
              />
              {t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.INFO.MIN_LENGTH}
            </li>
            <li className="flex gap-2">
              <img
                src={formik?.values.pin.length < 4 ? pendingSvg : isConsecutive(formik?.values.pin) ? errorSvg : doneSvg}
                alt="SVG"
              />
              {t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.INFO.NO_CONSECUTIVE}
            </li>
            <li className="flex gap-2">
              <img
                src={formik?.values.pin.length < 4 ? pendingSvg : isRepeated(formik?.values.pin) ? errorSvg : doneSvg}
                alt="SVG"
              />
              {t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.INFO.NO_REPEAT}
            </li>
          </ul>
          {<p className="text-gray-600 my-8">{t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN.NOTE}</p>}

          <label className="block mb-3">{t(data).DYNAMIC_USER_FORM.FIELDS.SECURITY_PIN.PIN_CONFIRM.LABEL}</label>
          <PinInput formik={formik} fieldName="confirmPin" />

          {formik?.errors.confirmPin && (
            <p className="text-red text-sm">
              <span className="icon-exclamation-triangle mr-1" />
              {formik?.errors.confirmPin}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const PinInput = ({ formik, fieldName }: any) => {
  const ONLY_NUMBERS_REGEX = /^\d*$/;

  const inputRef = useRef<HTMLInputElement>(null);
  const [showValue, setShowValue] = useState(false);
  const focusInput = () => inputRef.current?.focus();

  return (
    <div className="relative">
      <input
        value={fieldName === 'pin' ? formik?.values?.pin : formik?.values?.confirmPin}
        ref={inputRef}
        onChange={formik.handleChange(fieldName)}
        onKeyPress={e => !ONLY_NUMBERS_REGEX.test(e.key) && e.preventDefault()}
        className={`block w-full h-12 border px-2 mb-2 rounded-sm ${formik.errors[fieldName] && 'border-red'}`}
        maxLength={4}
        type={showValue ? 'text' : 'password'}
        css={css`
          &::-webkit-credentials-auto-fill-button {
            visibility: hidden;
          }
        `}
        inputMode="numeric"
      />
      <div
        className="absolute right-0 top-1/2 transform -translate-y-1/2 w-10 p-2 text-center cursor-pointer"
        onClick={() => {
          setShowValue(!showValue);
          focusInput();
        }}
      >
        {showValue ? <i className="icon-eye-open" /> : <i className="icon-eye-stroke" />}
      </div>
    </div>
  );
};

function isConsecutive(str: string = '') {
  if (str.length !== 4) return false;
  const [a, b, c, d] = str.split('').map(x => parseInt(x, 10));
  const diff = a - b;
  return Math.abs(diff) === 1 && b - c === diff && c - d === diff;
}

function isRepeated(str: string) {
  return /(\d)\1\1\1/.test(str);
}
