import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { AddFieldProps } from '../types';
import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LangContext } from '../../../../context/lang.context';
import { getMask } from '../utils/format';
import { CompanyContext } from '../../../../context/company.context';
import { getText } from '../../../../utils/functions';
import { createMask } from '../../../../utils/masks';
import { FieldLabel } from '../field-label';

export const DocumentField = ({ fields, schemas, formik }: AddFieldProps) => {
  const { t, lang } = useContext(LangContext);
  const { clientId, governmentValidationType } = useContext(CompanyContext);

  const data = useStaticQuery(graphql`
    query {
      allGovernmentIdentificationService {
        nodes {
          clientId
          locale
          DOCUMENT_TYPE {
            id
            regex
            value
          }
        }
      }
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              DOCUMENT {
                NUMBER {
                  LABEL_1
                  LABEL_2
                  REQUIRED
                  SPECIAL_CHARS
                  MIN_LENGTH
                  INVALID
                }
                HEADING
                TITLE
                TYPE {
                  LABEL
                  PLACEHOLDER
                  REQUIRED
                }
              }
            }
          }
        }
      }
    }
  `);

  const getRegex = (): string =>
    getText(data.allGovernmentIdentificationService.nodes, lang, clientId)
      .DOCUMENT_TYPE.find((e: any) => e?.id === formik?.values.documentType)
      ?.regex?.slice(1, -1);

  const documentTypes = getText(data.allGovernmentIdentificationService.nodes, lang, clientId).DOCUMENT_TYPE;
  const valueType = formik?.values.documentType;
  const documentNumber = fields?.documentNumber!;
  const documentType = fields?.documentType!;

  if (documentType?.required || documentType?.defaultValue) {
    schemas.documentType = Yup.string().required(t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.TYPE.REQUIRED);
  }
  if (governmentValidationType === 'Multiple') {
    schemas.documentNumber = Yup.string().validDocument(t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.NUMBER.INVALID);
  }

  const regex = getRegex();
  let mask: RegExp[];

  if (governmentValidationType === 'Multiple') {
    mask = createMask('NUMBER', 8);
    if (formik?.values.documentType === 'PASAPORTE') {
      mask = createMask('PASSPORT', 9);
    }
  } else {
    mask = getMask(valueType, regex);
  }

  return (
    <div key="document" className="mb-5 mt-10">
      <FieldLabel heading={t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.HEADING} required={documentType?.required} />
      <div className="flex gap-4">
        <div className="w-1/3">
          <label className="block mb-3">{t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.TYPE.LABEL}</label>
          <select
            defaultValue={formik?.values.documentType}
            onChange={(e: any) => {
              formik?.setFieldValue('documentType', e.target.value);
              formik?.setFieldValue('documentNumber', '');
            }}
            className={`input ${formik?.errors.documentType && 'border-red'} ${
              documentType?.readOnly && 'bg-gray-200'
            }`}
            disabled={documentType?.readOnly}
          >
            <option value="" disabled>
              {t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.TYPE.PLACEHOLDER}
            </option>
            {documentTypes.map(({ value, id }: any) => (
              <option key={id} value={id}>
                {value}
              </option>
            ))}
          </select>
          {formik?.errors.documentType && (
            <p className="text-red text-sm">
              <span className="icon-exclamation-triangle mr-1" />
              {formik?.errors.documentType}
            </p>
          )}
        </div>
        <div className="w-2/3">
          <label className="block mb-3">{t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.NUMBER.LABEL_1}</label>
          <MaskedInput
            value={formik?.values.documentNumber}
            onChange={formik?.handleChange('documentNumber')}
            className={`input ${formik?.errors.documentNumber && 'border-red'} ${
              documentNumber.readOnly && 'bg-gray-200'
            }`}
            mask={mask}
            maxLength={mask?.length}
            guide={false}
            disabled={documentNumber?.readOnly}
          />
          {formik?.errors.documentNumber && (
            <p className="text-red text-sm">
              <span className="icon-exclamation-triangle mr-1" />
              {formik?.errors.documentNumber}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
