import React from 'react';
import { AddFieldProps } from '../types';
import { FieldLabel } from '../field-label';

export const BirthDateField = ({ fields, formik }: AddFieldProps) => {
  const birthDateField = fields?.birthDate!;

  return (
    <div key="firstName" className="mb-5">
      <FieldLabel heading={'Fecha de Nacimiento'} required={birthDateField.required} />
      <input
        type="date"
        value={formik?.values.birthDate}
        onChange={formik?.handleChange('birthDate')}
        className={`input leading-10 ${formik?.errors.birthDate ? 'border-red' : ''} ${birthDateField?.readOnly ? 'bg-gray-200' : ''}`}
        disabled={birthDateField?.readOnly}
      />
      {formik?.errors.birthDate && (
        <p className="text-red text-sm">
          <span className="icon-exclamation-triangle mr-1" />
          {formik?.errors.birthDate}
        </p>
      )}
    </div>
  );
};
