import styled from '@emotion/styled';
import tw from 'twin.macro';
import { GatsbyImage } from 'gatsby-plugin-image';

const FooterStyled = styled.div`
  --safe-area-inset-bottom: env(safe-area-inset-bottom);

  ${tw`mt-auto pt-3 flex justify-center bg-gray-800`}
  padding-bottom: calc(${tw`pb-3`.paddingBottom} + var(--safe-area-inset-bottom));
`;

const ImgStyled = styled(GatsbyImage)`
  ${tw`w-8`}
`;

export { FooterStyled, ImgStyled };
