import React, { useContext } from 'react';
import { FooterStyled } from './styles';
import { CompanyContext } from '../../context/company.context';

const Footer = () => {
  const { getPublicURL } = useContext(CompanyContext);

  return (
    <FooterStyled>
      <div className="w-full flex">
        <img className="m-auto" loading="eager" src={getPublicURL(`/theme/assets/images/icon/icon.svg`)} />
      </div>
    </FooterStyled>
  );
};

export { Footer };
